module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Record Player Comparison","short_name":"Record Player Comparison","start_url":"/","background_color":"#fff","theme_color":"#e67e00","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"551edb3314a6da986463bc5748972a9c"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://recordplayercomparison.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../packages/gatsby-theme-seomass/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteTitle":"Record Player Comparison","siteDescription":"Compare the record players!","siteUrl":"https://recordplayercomparison.com","siteSubject":"Record Players","siteSubjectSlug":"players","siteLanguage":"en","siteImage":"/banner.jpg","siteTheme":{"mainColor":"#e67e00","secondColor":"#a55800","thirdColor":"#613400"},"siteNavigation":[{"name":"Record Players","slug":"/players/","submenu":"categories"},{"name":"Blog","slug":"/blog/"},{"name":"About","isCustomPage":true,"slug":"/about/","staticSubMenu":[{"name":"Contact us","slug":"/contact/"}]}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-windicss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
